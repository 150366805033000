@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root{
    --primary-color: #008293;
    --poppins: "Poppins", sans-serif;
    --text-color1: #24242C;
    --text-color2: #008293;
    --text-color3: #FAFAFA;
    --color: white;
    --hover1: #FAB70F;
}


#services{
   padding-top: 80px;
}

#services-section-1{
    background-image: url("../../assets/about_start.png");
    width: 100%;
}


/* Section 1 */

#services-section-1-1{
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    background: linear-gradient(to right bottom,#101010, rgba(255,255,255,0.1));
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

#services-section-1-2{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#services-section-1-3{
    background-color: var(--hover1);
    width: 8px;
    height: 130px;
    border-radius: 10px;
}

#services-section-1-4{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}


#services-p-1{
    font-family: var(--poppins);
    color: var(--text-color3);
    font-size: 18px;
    font-weight: 400;
}

#services-i{
    color: var(--hover1);
}

#services-h6{
    font-family: var(--poppins);
    color: var(--text-color3);
    font-weight: 600;
    font-size: 30px;
}

#services-p-2{
    font-family: var(--poppins);
    color: var(--text-color3);
    font-size: 18px;
    font-weight: 500;
}



/* Section 2 */

#services-section4-1{
    padding-top: 60px;
    padding-bottom: 60px;
}

#services-section4-div1-1{

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
 }
 
 #services-section4-h6-1{
     font-family: var(--poppins);
     color: var(--primary-color);
     font-size: 28px;
     font-weight: 500;
 }
 
 #services-section4-sub-content{
     font-family: var(--poppins);
     color: black;
     font-size: 18px;
     font-weight: 500;
 }
 
 #services-section4-row{
     padding-top: 20px;
     padding-bottom: 100px;
 }
 
 #services-section4-card{
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding: 10px;
     /* height: 40vh; */
     margin-top: 20px;
 }
 
 #services-section4-card:hover{
     background-color: var(--primary-color);
     padding: 10px;
 }
 
 
 #services-section4-svg-icon{
    margin-top: 20px;
     height: 90px;
     width: 90px;
     background-color: #FAFAFA;
     border-radius: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }
 
 #services-section4-card-icons{
     height: 45px;
     width: 45px;
 }
 
 
 
 #services-section4-card-title{
     font-family: var(--poppins);
     color: black;
     font-weight: 600;
     font-size: 18px;
 }
 
 #services-section4-card-title-hover{
     font-family: var(--poppins);
     color: white;
     font-weight: 600;
     font-size: 18px;
 }
 
 #services-section4-card-sub-content{
     margin-top: 10px;
     font-size: 14px;
     color: var(--text-color1);
     font-weight: 500;
     font-family: var(--poppins);
     margin-left: 20px;
     margin-right: 20px;
 }
 
 #services-section4-card-sub-content-hover{
     margin-top: 10px;
     font-size: 14px;
     color: var(--text-color3);
     font-weight: 500;
     font-family: var(--poppins);
     margin-left: 20px;
     margin-right: 20px;
 }
 
 #services-section4-card-button{
     margin-top: 10px;
     background-color: var(--primary-color);
     color: var(--text-color3);
     border: none;
     font-family: var(--poppins);
     font-weight: 500;
     font-size: 14px;
     padding: 10px;
     padding-left: 15px;
     padding-right: 15px;
 }
 
 #services-section4-card-button-hover{
     margin-top: 10px;
     background-color: var(--text-color3);
     color: var(--primary-color);
     border: none;
     font-family: var(--poppins);
     font-weight: 500;
     font-size: 14px;
     padding: 10px;
     padding-left: 15px;
     padding-right: 15px;
 }
 
 
 
 @media (max-width:410px){
     #services-section4-card-button{
         font-size: 12px;
      padding: 5px;
     }
 
     #services-section4-card-button-hover{
         font-size: 12px;
     padding: 5px;
     }
 }

 /* Section4 */

 #services-section4{
    padding-top: 30px;
    padding-bottom: 30px;
}

#services-section4-1{
    display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
}

#services-section4-logo-img{
    width: 250px;
    height: 120px;
    margin-left: 20px;
}

@media (max-width: 920px){
    #services-section4-1{
        flex-direction: row;
       flex-wrap: wrap;
       justify-content: center;
    }
    #services-section4-logo-img{
      margin-top: 20px;
    }
}

@media (max-width: 775px){
   
#services-p-2{
    font-size: 16px;
    text-align: left;
}
}

@media (max-width: 768px){

    #services-section-1-3{
        height: 160px;
    }

}