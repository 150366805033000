@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root{
    --primary-color: #008293;
    --poppins: "Poppins", sans-serif;
    --text-color1: #24242C;
    --text-color2: #008293;
    --text-color3: #FAFAFA;
    --color: white;
    --hover1: #FAB70F;
}

#Home{
  padding-top: 80px;
}
/* Section 1 */



#home-section1{
    padding-top: 50px;
    padding-bottom: 60px;
}

#home-section2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}


#home-section1-img{
    width: 600px;
    height: 500px;
}


#home-section4{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}




#home-section1-h6-1{
    font-family: var(--poppins);
    color: var(--text-color1);
    font-weight: 500;
    font-size: 36px;
    text-align: left;
}

#home-section1-h6-2{
    font-family: var(--poppins);
    color: var(--text-color1);
    font-weight: 500;
    font-size: 30px;
    text-align: left;
}


#home-section1-sub-content-1{
    color: var(--text-color2);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 19px;
    text-align: left;
}


#home-section1-sub-content-2{
    color: var(--text-color2);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 18px;
    text-align: left;
}

#home-section1-button{
    color: var(--text-color3);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 16px;
    border: none;
    background-color: var(--primary-color);
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
}

@media (max-width: 1024px){
    #home-section2{
        flex-direction: column;
        justify-content: center;
    }

    #home-section4{
        margin-top: 40px;
        flex-direction: column;
        align-items: flex-start;
    }


}

@media (max-width: 768px){
    #Home{
        padding: 20px;
      }
    #home-section1{
        padding-top: 30px;
        padding-bottom: 60px;
    }
    
    #home-section1-h6-1{
        font-size: 30px;
    }

    #home-section1-h6-2{
     font-size: 25px;
    }
}


@media (max-width: 576px){
    #home-section1-h6-1{
        font-size: 25px;
    }

    #home-section1-sub-content-1{
       font-size: 16px;
    }

    #home-section1-h6-2{
     font-size: 20px;
    }

    #home-section1-sub-content-2{
        font-size: 15px;
    }
}

@media (max-width: 540px){
    #home-section1-img{
        height: auto;
    }
}


/* Section 2 */


#home-section2-1{
    padding-top: 60px;
    padding-bottom: 40px;
}


#marquee{
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

#home-section2-row{
    display: flex;
    flex-direction: row;
    animation: marquee 20s linear infinite;
}

@keyframes marquee {
    0%{
      transform: translateX(0);
    }100%{
      transform: translateX(-100%);
    }
  }

#home-section2-card{
    background-color: transparent;
    border: none;
   width: 18rem;
   margin-left: 10px;
}

#home-section2-card-img-div{
    clip-path: circle(39.7% at 50% 50%);
    background-color: #d9d9d9;
}

#home-section2-card-img-div:hover{
    clip-path: circle(39.7% at 50% 50%);
    background-color: var(--primary-color);
}

#home-section2-card-img{
   height: 30vh;
  border: 2px solid black;
  clip-path: circle(37.9% at 50% 50%);
  object-fit: cover;
}


#home-section2-card-title{
    color: var(--text-color1);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 20px;
}




/* Section 3 */

#home-section3-1{
    padding-top: 100px;
    padding-bottom: 150px;
}

#home-section3-div-1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


#home-section3-div-2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 350px;
}

#home-section3-h6-1{
    color: var(--primary-color);
    font-family: var(--poppins);
    font-size: 20px;
    font-weight: 500;
    text-align: left;
}


#hr_line{
    width: 110px;
    height: 4px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 17px;
    text-align: left;
}



#home-section3-sub-content{
    font-family: var(--poppins);
    color: black;
    font-weight: 500;
    font-size: 22px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

#home-section3-sub-content1{
    font-family: var(--poppins);
    color: black;
    font-weight: 400;
    font-size: 15px;
    text-align: left;
    margin-bottom: 20px;
    line-height: 40px;
}

#home-section3-div-5{
    background-color: #F5F5F5; 
    padding: 20px;
    border-radius: 4px;
}

#home-section3-div-6{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#home-section3-div-7{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)); */
}

#home-section3-inside-1{
    background-color: white;
    height: 6vh;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#home-section3-inside-1-content{
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 400;
    color: black;
    margin-top: 15px;
    margin-left: 10px;
}

#home-section3-checkbox-icon{
    color: var(--primary-color);
    font-size: 23px;
    margin-left: 20px;
}

#home-section3-inside-2{
    background-color: white;
    height: 6vh;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#home-section3-inside-2-content{
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 400;
    color: black;
    margin-top: 15px;
    margin-left: 10px;
}

#home-section3-inside-3{
    background-color: white;
    height: 6vh;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#home-section3-inside-3-content{
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 400;
    color: black;
    margin-top: 15px;
    margin-left: 10px;
}

#home-section3-div-8{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#home-section3-inside-4{
    background-color: white;
    height: 6vh;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#home-section3-inside-4-content{
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 400;
    color: black;
    margin-top: 15px;
    margin-left: 10px;
}

#home-section3-inside-5{
    background-color: white;
    height: 6vh;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#home-section3-inside-5-content{
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 400;
    color: black;
    margin-top: 15px;
    margin-left: 10px;
}


#home-section3-inside-6{
    background-color: white;
    height: 6vh;
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#home-section3-inside-6-content{
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 400;
    color: black;
    margin-top: 15px;
    margin-left: 10px;
}


@media (max-width: 1025px){
     #home-section3-slide-img{
        display: none;
     }
}

@media (max-width: 540px){
    #home-section3-1{
       padding-bottom: 50px;
    }
}

@media (max-width: 443px){

   #home-section3-div-2{
    width: 100%;
     flex-direction: column;
     align-items: flex-start;
   }
}

@media (max-width: 400px){
    #hr_line{
      width: 60px;
    }

    #home-section3-sub-content{
        font-size: 18px;
    }
    #home-section3-sub-content1{
     font-size: 13px;
     line-height: 30px;
    }
    #home-section3-checkbox-icon{
        font-size: 16px;
    }
}

@media (max-width: 350px){
   #home-section3-inside-1-content{
    font-size: 12px;
   }
   #home-section3-inside-2-content{
    font-size: 12px;
   }
   #home-section3-inside-3-content{
    font-size: 12px;
   }
   #home-section3-inside-4-content{
    font-size: 12px;
   }
   #home-section3-inside-5-content{
    font-size: 12px;
   }
   #home-section3-inside-6-content{
    font-size: 12px;
   }
}


/* Section 4 */


#home-section4-div1-1{
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 30px;
}

#home-section4-h6-1{
    font-family: var(--poppins);
    color: var(--primary-color);
    font-size: 28px;
    font-weight: 500;
}

#home-section4-sub-content{
    font-family: var(--poppins);
    color: black;
    font-size: 18px;
    font-weight: 500;
}

#home-section4-row{
    padding-top: 20px;
    padding-bottom: 100px;
}

#home-section4-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-top: 20px;
}

#home-section4-card:hover{
    background-color: var(--primary-color);
    padding: 10px;
}


#home-section4-svg-icon{
    height: 90px;
    width: 90px;
    background-color: #FAFAFA;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#home-section4-card-icons{
    height: 45px;
    width: 45px;
}



#home-section4-card-title{
    font-family: var(--poppins);
    color: black;
    font-weight: 600;
    font-size: 18px;
}

#home-section4-card-title-hover{
    font-family: var(--poppins);
    color: white;
    font-weight: 600;
    font-size: 18px;
}

#home-section4-card-sub-content{
    margin-top: 10px;
    font-size: 14px;
    color: var(--text-color1);
    font-weight: 500;
    font-family: var(--poppins);
    margin-left: 20px;
    margin-right: 20px;
}

#home-section4-card-sub-content-hover{
    margin-top: 10px;
    font-size: 14px;
    color: var(--text-color3);
    font-weight: 500;
    font-family: var(--poppins);
    margin-left: 20px;
    margin-right: 20px;
}

#home-section4-card-button{
    margin-top: 10px;
    background-color: var(--primary-color);
    color: var(--text-color3);
    border: none;
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 14px;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

#home-section4-card-button-hover{
    margin-top: 10px;
    background-color: var(--text-color3);
    color: var(--primary-color);
    border: none;
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 14px;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
}



@media (max-width:410px){
    #home-section4-card-button{
        font-size: 12px;
     padding: 5px;
    }

    #home-section4-card-button-hover{
        font-size: 12px;
    padding: 5px;
    }
}

/* Section 5 */

#home-section5-1{
  background: url("../../assets/home_background_img.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
  width: 100%;
}


#home-section5-2{
    padding-top:60px;
    padding-bottom: 60px;
}



#home-section5-4{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#home-section5-img{
    width: 500px;
    height: 500px;
}

#home-section5-h6-1{
    font-size: 28px;
    font-weight: 600;
    font-family: var(--poppins);
    color: var(--text-color3);
}

#home-section5-6{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


#home-section5-8{
    display: flex;
    flex-direction: column;
    width: 500px;
    background-color: var(--text-color3);
    /* padding: 10px; */
    margin-top: 10px;
}

#home-section5-9{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 18px;
}

#home-section5-h6{
    font-size: 22px;
    font-weight: 600;
    font-family: var(--poppins);
    color: var(--primary-color);
}

#home-minus-icon{
    font-size: 20px;
    color: var(--primary-color);
}

#home-plus-icon{
    font-size: 20px;
    color: var(--primary-color);
}


#home-section5-10{
   padding-top: 10px;
   padding-left: 20px;
   padding-right: 10px;
}


#home-section5-p{
display: none;
}

#home-section5-p-click{
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    font-family: var(--poppins);
}

@media (max-width: 1024px){
    #home-section5-4{
        flex-direction: column;
        align-items: center;
    }

    #home-section5-img{
        display: none;
    }

}

@media (max-width: 769px){
    #home-section5{
        padding: 0px;
    }
}


@media (max-width: 580px){
    #home-section5-8{
        width: 450px;
    }
}

@media (max-width: 540px){
  
    #home-section5-h6{
        font-size: 18px;
    }

    #home-plus-icon{
        margin-bottom: 10px;
    }
    #home-minus-icon{
        margin-bottom: 10px;
    }


}

@media (max-width: 516px){
    #home-section5-8{
        width: 400px;
    }
}

@media (max-width: 464px){
    #home-section5-8{
        width: 350px;
    } 
}

@media (max-width: 416px){
    #home-section5-8{
        width: 300px;
    } 

    #home-section5-h6{
        font-size: 18px;
    }
}

@media (max-width: 363px){
    #home-section5-8{
        width: 250px;
    } 

    #home-section5-h6{
        font-size: 13px;
    }

    #home-plus-icon{
        font-size: 16px;
    }

    #home-minus-icon{
        font-size: 16px;
    }

    #home-section5-p-click{
        font-size: 16px;
    }
}

/* Section 6 */

#section6{
    padding-top: 30px;
    padding-bottom: 90px;
}

#section6-1{
   display: flex;
   flex-direction: row;
}

#scroll-home-section-logo{
    display: flex;
    width: 100%;
    overflow: hidden;
    /* -webkit-mask-image: linear-gradient(90deg); */
    animation: scroll;
}



#row-home-logo-section{
    display: flex;
    flex-direction: row;
    animation: scroll 20s linear infinite;
}

@keyframes scroll{
    0%{
        transform: translateX(0)
    }
    100%{
        transform: translateX(-100%);
    }
}

#section6-logo-img{
    width: 250px;
    height: 120px;
    margin-left: 20px;
}

@media (max-width: 920px){
    /* #section6-1{
        flex-direction: row;
       flex-wrap: wrap;
       justify-content: center;
    } */
    #section6-logo-img{
      margin-top: 20px;
    }
}

/* Section 7 */


#section7{
    padding-top: 50px;
    padding-bottom: 50px;
}

#section7-head{
  font-family: var(--poppins);
  color: var(--primary-color);
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}

#section7-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#section7-2{
    background-color: var(--color);
    width: 400px;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

/* location */

#section7-4{
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}


#home-location-icon{
    color: var(--text-color2);
    font-size: 20px;
}


#section7-5{
    width: 40px;
    height: 40px;
    border: 1px solid var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

#section7-6{

    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

#section7-h6-1{
margin-top: 15px;
    font-family: var(--poppins);
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color1);
    text-align: left;
}

#section7-p-1{
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 400;
    color: var(--text-color1);
    text-align: left;
}



/* Phone  */


#section7-7{
    padding-top: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

#section7-8{
    width: 40px;
    height: 40px;
    border: 1px solid var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}


#home-phone-icon{
    color: var(--text-color2);
    font-size: 20px;
}




#section7-9{

    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

#section7-h6-2{
margin-top: 15px;
    font-family: var(--poppins);
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color1);
    text-align: left;
}

#section7-p-2{
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 400;
    color: var(--text-color1);
    text-align: left;
}



/* Email */


#section7-10{
   padding-top: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}




#section7-11{
    width: 40px;
    height: 40px;
    border: 1px solid var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

#home-email-icon{
    color: var(--text-color2);
    font-size: 20px;
}


#section7-12{

    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

#section7-h6-3{
margin-top: 15px;
    font-family: var(--poppins);
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color1);
    text-align: left;
}

#section7-p-3{
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 400;
    color: var(--text-color1);
    text-align: left;
}

/* Section 7 Part 2 */

#section7-form{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 20px;
}

#section7-form-two-input{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#section7-name-input{
  width: 400px;
  border: 2px solid var(--primary-color);
  font-size: 14px;
padding: 15px;
  font-family: var(--poppins);
  font-weight: 500;
  color: var(--primary-color);
  border-radius: 2px;
}

#section7-phone-input{
  width: 400px;
  border: 2px solid var(--primary-color);
  font-size: 14px;
padding: 15px;
  font-family: var(--poppins);
  font-weight: 500;
  color: var(--primary-color);
  border-radius: 2px;
}

#section7-email-input{
    border: 2px solid var(--primary-color);
  font-size: 14px;
padding: 15px;
  font-family: var(--poppins);
  font-weight: 500;
  color: var(--primary-color);
    width: 820px;
    border-radius: 2px;
}

#section7-message-input{
    border: 2px solid var(--primary-color);
  font-size: 14px;
padding: 10px;
padding-left: 20px;
  font-family: var(--poppins);
  font-weight: 500;
  color: var(--primary-color);
  height: 130px;
  width: 820px;
  border-radius: 2px;
}

#section7-form-submit-button{
   font-family: var(--poppins);
   font-size: 16px;
   font-weight: 500;
   border: none;
   background-color: var(--primary-color);
   color: var(--text-color3);
   width: 200px;
   padding: 10px;
   border-radius: 5px;
}


@media (max-width: 1024px){



    #section6-1{
        margin-top: 20px;
    }

    #section7-1{
        flex-direction: column;
        gap: 40px;
        justify-content: center;
        align-items: center;
    }
}


@media (max-width: 830px){
    #section7-form-two-input{
     flex-direction: column;
    }

    #section7-form{
        padding: 10px;
    }


    #section7-name-input{
        width: 800px;
    }

    #section7-phone-input{
        width: 800px;
    }

    #section7-email-input{
        width: 800px;
    }

    #section7-message-input{
        width: 800px;
    }

}

@media (max-width: 810px){
    
    #section7-name-input{
        width: 750px;
    }

    #section7-phone-input{
        width: 750px;
    }

    #section7-email-input{
        width: 750px;
    }

    #section7-message-input{
        width: 750px;
    }

}

@media (max-width: 760px){
    
    #section7-name-input{
        width: 700px;
    }

    #section7-phone-input{
        width: 700px;
    }

    #section7-email-input{
        width: 700px;
    }

    #section7-message-input{
        width: 700px;
    }

}

@media (max-width: 720px){
    
    #section7-name-input{
        width: 650px;
    }

    #section7-phone-input{
        width: 650px;
    }

    #section7-email-input{
        width: 650px;
    }

    #section7-message-input{
        width: 650px;
    }

}

@media (max-width: 670px){
    
    #section7-name-input{
        width: 600px;
    }

    #section7-phone-input{
        width: 600px;
    }

    #section7-email-input{
        width: 600px;
    }

    #section7-message-input{
        width: 600px;
    }

}


@media (max-width: 630px){
    
    #section7-name-input{
        width: 550px;
    }

    #section7-phone-input{
        width: 550px;
    }

    #section7-email-input{
        width: 550px;
    }

    #section7-message-input{
        width: 550px;
    }

}

@media (max-width: 570px){
    
    #section7-name-input{
        width: 500px;
    }

    #section7-phone-input{
        width: 500px;
    }

    #section7-email-input{
        width: 500px;
    }

    #section7-message-input{
        width: 500px;
    }

}

@media (max-width: 520px){
    
    #section7-name-input{
        width: 450px;
    }

    #section7-phone-input{
        width: 450px;
    }

    #section7-email-input{
        width: 450px;
    }

    #section7-message-input{
        width: 450px;
    }

}


@media (max-width: 460px){
    
    #section7-name-input{
        width: 400px;
    }

    #section7-phone-input{
        width: 400px;
    }

    #section7-email-input{
        width: 400px;
    }

    #section7-message-input{
        width: 400px;
    }

}

@media (max-width: 420px){
    

    #section7-2{
        width: 350px;
    }

    #section7-name-input{
        width: 350px;
    }

    #section7-phone-input{
        width: 350px;
    }

    #section7-email-input{
        width: 350px;
    }

    #section7-message-input{
        width: 350px;
    }

}


@media (max-width: 370px){
    
    

    #section7-2{
        width: 300px;
    }

    #section7-6{
        width: 250px;
    }

    #section7-name-input{
        width: 300px;
    }

    #section7-phone-input{
        width: 300px;
    }

    #section7-email-input{
        width: 300px;
    }

    #section7-message-input{
        width: 300px;
    }

}