@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root{
    --primary-color: #008293;
    --poppins: "Poppins", sans-serif;
   --color1:#bfbfbf;
   --color2: #404042;
   --color3: #F9FFFF;
   --color4: #111111;
   --hover1: #FAB70F;
}

#copywrite{
    background-color: black;
   height: 60px;
}

#copywrite1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#copywrite2{
    color: var(--color1);
    font-family: var(--poppins);
    font-size: 14px;
    margin-top: 20px;
}

#copywrite_href{
  text-decoration: none;
  color: var(--color1);
}

#copywrite_href:hover{
    color: var(--hover1);
}


@media (max-width: 572px){
    #copywrite{
       height: 120px; 
    }
    #copywrite1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width:322px){
    #copywrite{
        height: 150px; 
     } 
}