@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root{
    --primary-color: #008293;
    --poppins: "Poppins", sans-serif;
    --text-color1: #24242C;
    --text-color2: #008293;
    --text-color3: #FAFAFA;
    --color: white;
    --hover1: #FAB70F;
}

#career{
    padding-top: 80px;
}

#career-section-1{
    background-image: url("../../assets/about_start.png");
    width: 100%;
}

/* section 1 */

#career-section-1-1{
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    background: linear-gradient(to right bottom,#101010, rgba(255,255,255,0.1));
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}


#career-section-1-2{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#career-section-1-3{
    background-color: var(--hover1);
    width: 8px;
    height: 130px;
    border-radius: 10px;
}

#career-section-1-4{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}


#career-p-1{
    font-family: var(--poppins);
    color: var(--text-color3);
    font-size: 18px;
    font-weight: 400;
}

#career-i{
    color: var(--hover1);
}

#career-h6{
    font-family: var(--poppins);
    color: var(--text-color3);
    font-weight: 600;
    font-size: 30px;
}

#career-p-2{
    font-family: var(--poppins);
    color: var(--text-color3);
    font-size: 18px;
    font-weight: 500;
}


/* Section2 */

#career-section-2{
    padding-bottom: 50px;
}

#career-section-2-1{
    padding-top: 60px;
}

#career-section2-3-h6{
    font-size: 28px;
    color: var(--primary-color);
    font-family: var(--poppins);
    font-weight: 600;
   
}

#career-section2-3-p{
    padding-top: 10px;
    color: var(--text-color1);
    font-size: 20px;
    font-family: var(--poppins);
    font-weight: 500;
    padding-bottom: 30px;
}

/* section 1 */

#career-section2-4{
    padding-top: 40px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 60px;
}

#carrer-section2-5-img{
    border-radius: 6px;
    width: 600px;
}

#career-section2-6{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#career-section2-6-h6{
    color: var(--primary-color);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 28px;
    text-align: left;
}


#career-section2-6-p{
    color: var(--text-color1);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    line-height: 40px;
}

/* Section 2-2 */

#career-section2-7{
    padding-top: 40px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 60px;
}

#career-section2-8-h6{
    color: var(--primary-color);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 28px;
    text-align: left;
}


#career-section2-8-p{
    color: var(--text-color1);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    line-height: 40px;
}




#career-section2-9{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#carrer-section2-9-img{
    border-radius: 6px;
    width: 600px;
}

/* Section 2-3 */

#career-section2-10{
    padding-top: 40px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 60px;
}

#carrer-section2-11-img{
    border-radius: 6px;
    width: 600px;
}

#career-section2-12{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#career-section2-12-h6{
    color: var(--primary-color);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 28px;
    text-align: left;
}


#career-section2-12-p{
    color: var(--text-color1);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    line-height: 40px;
}

/* Section 2-4 */


#career-section2-13{
    padding-top: 40px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 60px;
}

#career-section2-14-h6{
    color: var(--primary-color);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 28px;
    text-align: left;
}


#career-section2-14-p{
    color: var(--text-color1);
    font-family: var(--poppins);
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    line-height: 40px;
}




#career-section2-15{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#carrer-section2-15-img{
    border-radius: 6px;
    width: 600px;
}





/* Section 3 */

#career-section-3{
    background-color: var(--primary-color);
    padding-top: 60px;
    padding-bottom: 30px;
}



#career-section-3-1-h6-1{
   font-size: 30px;
   font-weight: 500;
   font-family: var(--poppins);
   color: var(--color);

}


#career-section-3-2{
    display: flex;
    flex-direction: row;
    align-items: center;
  gap: 60px;
}


#career-section-3-2-1{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

#career-section-3-2-p{
     color: var(--text-color3);
     font-size: 18px;
     font-family: var(--poppins);
     font-weight: 500;
     text-align: left;
}


#career-section-3-2-button{
    margin-top: 15px;
    background-color: var(--color);
    color: var(--primary-color);
    border: none;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    
}

#career-section-3-img{
    width: 400px;
}

@media (max-width: 960px){
    #career-section-3-2{
        margin-top: 40px;
        flex-direction: column;
        gap: 20px;
    }

    #career-section-3-img{
        width: 500px;
    }

    #career-section2-4{
       flex-direction: column;
       align-items: center;
       width: 100%;
    }

    #career-section2-7{
     flex-direction: column-reverse;
    }

    #career-section2-10{
        flex-direction: column;
    }

    #career-section2-13{
        flex-direction: column-reverse;
    }

}

@media (max-width: 775px){    
    #career-p-2{
        font-size: 16px;
        text-align: left;
    }
    }

    @media (max-width: 540px){
        #career-section-3{
            width: 100%;
        }
        
        #career-section-3-img{
            width: 450px;
        }

        #career-section2-6-p{
            text-align: justify;  
        }

        #career-section2-8-p{
            text-align: justify;  
        }

        #career-section2-12-p{
            text-align: justify;
        }

        #career-section2-14-p{
            text-align: justify;
        }

        #career-section-3-2-p{
            text-align: justify;
        }
    }


    @media (max-width: 650px){

        #carrer-section2-5-img{
            width: 100%;
        }

        #carrer-section2-9-img{
            width: 100%;
        }

        #carrer-section2-11-img{
            width: 100%;
        }

        #carrer-section2-15-img{
            width: 100%;
        }
    }

    @media (max-width: 536px){
        #career-section-1-3{
            height: 140px;
        }
    }


    @media (max-width: 440px){
        #career-section-3-img{
            width: 380px;
        }
    }

    @media (max-width: 410px){

        #career-section2-6-h6{
            font-size: 24px;
        }

        #career-section2-8-h6{
            font-size: 24px;
        }

        #career-section2-12-h6{
            font-size: 24px;
        }

        #career-section2-14-h6{
            font-size: 24px;
        }
    }


    @media (max-width: 380px){
        #career-section-3-img{
            width: 340px;
        }
    }

    @media (max-width: 346px){
        #career-section-3-img{
            width: 300px;
        }
    }
