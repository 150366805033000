@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root{
    --primary-color: #008293;
    --poppins: "Poppins", sans-serif;
    --text-color1: #24242C;
    --text-color2: #008293;
    --text-color3: #FAFAFA;
    --color: white;
    --hover1: #FAB70F;
}

#about{
    padding-top: 80px;
}

#about1{
    background-image: url("../../assets/about_start.png");
    width: 100%;
}

#about2{
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    background: linear-gradient(to right bottom,#101010, rgba(255,255,255,0.1));
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}


#about3{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#about4{
    background-color: var(--hover1);
    width: 8px;
    height: 130px;
    border-radius: 10px;
}

/* Section 2 */


#about5{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

#about-p-1{
    font-family: var(--poppins);
    color: var(--text-color3);
    font-size: 18px;
    font-weight: 400;
}

#about-i{
    color: var(--hover1);
}

#about-h6{
    font-family: var(--poppins);
    color: var(--text-color3);
    font-weight: 600;
    font-size: 30px;
}

#about-p-2{
    font-family: var(--poppins);
    color: var(--text-color3);
    font-size: 18px;
    font-weight: 500;
}


/* Section 3 */

#about-section3{
     padding-top: 50px;
     padding-bottom: 50px;
}

#about-section3-1{
    padding-bottom: 40px;
}

#about-section3-h6-1{
    font-family: var(--poppins);
    color: var(--primary-color);
    font-size: 28px;
    font-weight: 500;
    padding-bottom: 40px;
}

#about-section3-3{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
      padding-bottom: 40px;
}

#about-section3-3-1{
    height: 200px;
    width: 200px;
    background-color: #008293;
    color: #101010;
}

#about-section3-3-2{
    height: 200px;
    width: 200px;
    background-color: #008293;
    color: #101010;
}


#about-section3-4{
    background-color: var(--primary-color);
    width: 300px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
}



#about-section3-5{
    width: 100px;
    height: 60px;
    background-color: var(--text-color3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

#about-section3-6-p{
  font-family: var(--poppins);
  color: var(--text-color3);
  font-size: 17px;
  font-weight: 500;
  margin-top: 20px;
  margin-left: 5px;
}

#about-section3-4-not-active{
    background-color: var(--text-color3);
    width: 300px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
}

#about-section3-5-not-active{
    width: 100px;
    height: 60px;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

#about-section3-6-p-not-active{
  font-family: var(--poppins);
  color: var(--text-color1);
  font-size: 17px;
  font-weight: 500;
  margin-top: 20px;
  margin-left: 5px;
}


#about-section3-7{
    padding-top: 90px;
    padding-bottom: 90px;
}

#about-section3-9{
    display: flex;
    flex-direction: row;
   gap: 100px;  
}

#about-section3-12-h6-1{
    font-family: var(--poppins);
    font-size: 24px;
    font-weight: 500;
    text-align: left;
}

#about-section3-13-flex{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}

#about-section3-14{
    background-color: var(--primary-color);
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:  723px;
}

#about-section3-14-p{
    color: var(--text-color3);
    font-size: 18px;
    font-weight: 500;
    font-family: var(--poppins);
    margin-top: 15px;
    margin-left: 20px;
}

#about-section3-angle{
    color: var(--text-color3);
   font-size: 25px;
   margin-right: 10px;
}

#about-section3-15{
    background-color: var(--color);
    border: 1px solid var(--primary-color);
    padding: 10px;
}

#about-section3-15-not-click{
    display: none;
}

#about-section3-16{
    color: var(--text-color1);
    font-size: 14px;
    font-weight: 500;
    font-family: var(--poppins);
    text-align: left;
    width:  700px;
}



/* Click 2 */

#about-section3-17{
    padding-top: 90px;
    padding-bottom: 90px;
}

#about-section3-19{
    display: flex;
    flex-direction: row;
   gap: 100px;  
}

#about-section3-22-h6-1{
    font-family: var(--poppins);
    font-size: 24px;
    font-weight: 500;
    text-align: left;
}

#about-section3-23-flex{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}

#about-section3-24{
    background-color: var(--primary-color);
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:  700px;
}

#about-section3-24-p{
    color: var(--text-color3);
    font-size: 18px;
    font-weight: 500;
    font-family: var(--poppins);
    margin-top: 15px;
    margin-left: 20px;
    width:  700px;
}

#about-section3-angle{
    color: var(--text-color3);
   font-size: 25px;
   margin-right: 10px;
}

#about-section3-25{
    background-color: var(--color);
    border: 1px solid var(--primary-color);
    padding: 10px;
}

#about-section3-25-not-click{
    display: none;
}

#about-section3-26{
    color: var(--text-color1);
    font-size: 14px;
    font-weight: 500;
    font-family: var(--poppins);
    text-align: left;
}


@media (max-width: 1024px){
    #about-section3-10{
        display: none;
    }


    #about-section3-11{
        display: flex;
        flex-direction: column;
        align-items: center;
      width: 100%;
    }

    #about-section3-21{
        display: flex;
        flex-direction: column;
        align-items: center;
      width: 100%;
    }



    #about-section3-12-h6-1{
        text-align: left;
    }


    #about-section3-22-h6-1{
        text-align: left;
    }

    #about-section3-img-1{
        display: none;
    }

    #about-section3-19{
        flex-direction: column;
        gap: 0px;
        align-items: center;
    }

    #about-section3-22-h6-1{
        text-align: center;
    }

    #about-section3-26{
        width: 658px;
    }

}


@media (max-width: 775px){

   

    #about-section3-3{
        flex-direction: row;
        flex-wrap: wrap;
    }

    
#about-p-2{
    font-size: 16px;
    text-align: left;
}
}

@media (max-width: 768px){

    #about4{
        height: 160px;
    }

}


@media (max-width: 740px){
    #about-section3-14{
        width: 650px;
    }
   
    #about-section3-24{
        width: 650px;
    }

    #about-section3-16{
      width: 627px;
    }

    #about-section3-26{
        width: 627px;
      }
  


}

@media (max-width: 670px){
    #about-section3-14{
        width: 600px;
    }
   
    #about-section3-24{
        width: 600px;
    }

    #about-section3-16{
      width: 578px;
    }

    #about-section3-26{
        width: 578px;
      }
}

@media (max-width: 610px){
    #about-section3-14{
        width: 550px;
    }

    #about-section3-24{
        width: 550px;
    }
   
    #about-section3-16{
      width: 520px;
    }

    #about-section3-26{
        width: 520px;
      }
}

@media (max-width: 560px){
    #about-section3-14{
        width: 500px;
    }
   
    #about-section3-24{
        width: 500px;
    }
   


    #about-section3-16{
      width: 479px;
    }

    #about-section3-26{
        width: 479px;
      }
}

@media (max-width: 540px){
    #home-section3-sub-content1{
        text-align: justify;
    }
}


@media (max-width: 510px){
    #about-section3-12-h6-1{
        font-size: 20px;
    }

    #about-section3-22-h6-1{
        font-size: 20px;
    }

    #about-section3-14{
        width: 450px;
    }
   
    
    #about-section3-24{
        width: 450px;
    }

    #about-section3-16{
      width: 428px;
    }

    #about-section3-26{
        width: 428px;
      }
}

@media (max-width: 460px){
    #about-section3-14{
        width: 400px;
    }

    #about-section3-24{
        width: 400px;
    }
   
    #about-section3-16{
      width: 370px;
    }

    #about-section3-26{
        width: 370px;
      }
}

@media (max-width: 410px){
    #about-section3-12-h6-1{
        font-size: 18px;
    }

    #about-section3-22-h6-1{
        font-size: 18px;
    }
    #about-section3-14{
        width: 350px;
    }

    #about-section3-24{
        width: 350px;
    }
   
    #about-section3-16{
      width: 320px;
    }

    #about-section3-26{
        width: 320px;
      }
}

@media (max-width: 370px){
    #about-section3-12-h6-1{
        font-size: 16px;
    }

    #about-section3-22-h6-1{
        font-size: 16px;
    }

    #about-section3-14{
        width: 300px;
    }

    #about-section3-24{
        width: 300px;
    }
   
    #about-section3-16{
      width: 270px;
    }

    #about-section3-26{
        width: 270px;
      }
    #about-section3-14-p{
        font-size: 13px;
    }

    #about-section3-24-p{
        font-size: 13px;
    }

}

@media (max-width: 340px){
    #about-section3-12-h6-1{
        font-size: 16px;
        text-align: center;
    }

    #about-section3-22-h6-1{
        font-size: 16px;
        text-align: center;
    }
}